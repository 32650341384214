// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
window.jQuery = $;
window.$ = $;

require("bootstrap");

// Remove this demo code, that's only here to show how the .env file works!
if (process.env["HELLO"]) {
  console.log(`Hello ${process.env.HELLO}!`);
}

$('button.jb-btn-order').on('click', function(event) {
  event.preventDefault(); // To prevent following the link (optional)
  window.location.href='mailto:info@jaeb.eu?subject=' + encodeURI('Bestellanfrage&body=Hallo liebes JÄB Team,\n\nich möchte die Initiative SPENDEN und GENIESSEN und damit die Renovierung der Pfarrkirche St. Jakobus d.Ä. unterstützen.\n\nFolgende Produkte möchte ich bestellen:\n(Bitte die gewünschte Anzahl eintragen bzw. nicht gewünschte Produkte entfernen!)\n\n<Anzahl> : JÄB TROCKEN\n\nBitte schickt mir ein Angebot!\n\nVielen Dank & Gruß,\n<Deine Kontaktdaten>');
});
